/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@angular/material/theming';
@import 'app/app-theme';
@import 'app/assets/highlightjs/material-light.css';

@include mat-core();

$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-pink, A200, A100, A400);

$theme: mat-light-theme($primary, $accent);
@include angular-material-theme($theme);
@include boilerplate-app-theme($theme);



body {
  font-family: "Roboto","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  margin: 0;
}

.page-content {
  padding: 0 2em;
}

.mat-card-content,
.mat-card-header .mat-card-title,
.mat-expansion-panel-content {
  font-size: 16px;
}

.mat-panel-title {
  display: inline-block;
  vertical-align: middle;
}

.mat-form-field.ng-dirty.ng-touched.ng-valid {

  $valid-color: #42A948;

  .mat-form-field-label {
    color: $valid-color;
  }

  .mat-form-field-underline {
    background-color: $valid-color;
  }
}

.mat-dialog-sm {
  min-width: 40vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-dialog-md {
  min-width: 50vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-dialog-lg {
  min-width: 80vw;

  @media screen and (max-width: 959px) {
    min-width: 90vw;
  }
}

.mat-button-icon {
  margin-right: 16px;
}

.mat-icon.mat-icon-lg {
  width: 48px;
  height: 48px;
  font-size: 48px;
}

.vertical-center {
  display: inline-block;
  vertical-align: middle;
}

#toasta.toasta-position-top-right {
  top: 80px;
  right: 20px;
}

#toasta .toast-text .toast-msg {
  max-width: 1000px;
  max-height: 800px;
  display: inline-block;
  overflow: auto;
}


.chartContainer canvas {
    max-height: 250px;
    width: auto;
}

.chartContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
